<script>
import { BCardText, BContainer, BRow, BTab, BTabs, BCard, BCol, BButton } from 'bootstrap-vue';
import { quillEditor } from 'vue-quill-editor';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

export default {
    name: 'InformationsSinglePage',
    components: {
        BRow,
        BContainer,
        BTabs,
        BTab,
        BCardText,
        quillEditor,
        BCard,
        BCol,
        BButton
    },
    props: {
        value: Object
    },
    computed: {
        computedModelValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        }
    },
    data: () => ({
        isButtonActive: false,
        editorOption: {
            modules: {
                toolbar: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],

                    ['bold', 'italic', 'underline', 'strike'],
                    ['blockquote', 'code-block'],

                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ script: 'sub' }, { script: 'super' }],

                    [{ color: [] }, { background: [] }],
                    [{ align: [] }]
                ]
            }
        }
    }),
    methods: {
        change() {
            this.isButtonActive = true;
        }
    }
};
</script>

<template>
    <b-row>
        <b-col cols="12">
            <b-tabs>
                <b-tab
                    v-for="(policy, index) in computedModelValue.name"
                    :key="index"
                    :title="policy"
                >
                    <div class="card">
                        <quill-editor
                            v-model="computedModelValue.content[index]"
                            :options="editorOption"
                            @change="change"
                        />
                    </div>
                </b-tab>
            </b-tabs>
        </b-col>
        <b-col cols="12">
            <slot name="button" />
        </b-col>
    </b-row>
</template>

<style scoped>
.tabs {
    width: 100%;
}
</style>
