<script>
import api from '@/services/api';
import InformationsSinglePage from '@/components/InformationsSinglePage';
import { BButton } from 'bootstrap-vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';

export default {
    name: 'Policies',
    components: { ProgressLinear, InformationsSinglePage, BButton },
    data: () => ({
        policies: {},
        requestPending: true
    }),
    methods: {
        async getPolicies() {
            this.requestPending = true;
            const { data } = await api.policies.get();
            this.policies = data;
            this.requestPending = false;
        },

        async updatePolicies() {
            await api.policies
                .put(this.policies.id, this.policies)
                .then(() => {
                    this.$toast.success(this.$t('success.updated.title'));
                })
                .catch(() => {
                    this.$toast.error(this.$t('error.smth'));
                });
        }
    },
    mounted() {
        this.getPolicies();
    }
};
</script>

<template>
    <div>
        <h1 class="mb-2">
            {{ $t('navigation.policies') }}
        </h1>
        <progress-linear v-if="requestPending" />
        <informations-single-page v-else v-model="policies">
            <template #button>
                <b-button
                    v-if="hasAccess('policies', 'update')"
                    variant="success"
                    class="float-right"
                    @click="updatePolicies"
                >
                    {{ $t('button.save') }}
                </b-button>
            </template>
        </informations-single-page>
    </div>
</template>
